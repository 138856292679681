import React from "react"
import { Layout2022Q3 } from "@layout"
import { HeroFeature2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import {
  MainBlockCareersContainer,
  MainBlockJoinColony,
} from "@components/main-blocks"

const Careers = () => (
  <Layout2022Q3
    backgroundImage="careers"
    hero={
      <HeroFeature2022Q3
        title="Careers"
        subtitle="Search and apply to your dream career here with ATM.com&reg;"
      />
    }
  >
    <SEO title="Careers &amp; Job Openings">
      <script src="/workable.js" type="text/javascript"></script>
    </SEO>
    <MainBlockCareersContainer />
    <MainBlockJoinColony />
  </Layout2022Q3>
)

export default Careers
